export const Mail = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 3H4C2.34325 3 1 4.34325 1 6V18C1 19.6567 2.34325 21 4 21H20C21.6567 21 23 19.6567 23 18V6C23 4.34325 21.6567 3 20 3ZM20 5C20.1155 5 20.2265 5.0195 20.3295 5.05575L12 11.7195L3.6705 5.05575C3.77639 5.01865 3.8878 4.9998 4 5H20ZM20 19H4C3.73478 19 3.48043 18.8946 3.29289 18.7071C3.10536 18.5196 3 18.2652 3 18V7.0805L11.3752 13.7808C11.5577 13.927 11.7788 14 12 14C12.2212 14 12.4423 13.927 12.6248 13.7808L21 7.0805V18C21 18.2652 20.8946 18.5196 20.7071 18.7071C20.5196 18.8946 20.2652 19 20 19Z"
                fill="black"
            />
        </svg>
    );
};
