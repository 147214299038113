export const ExelIcon = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_2_502)">
            <path
                d="M12.5522 0H14.1715V2.18076C16.9002 2.18076 19.6288 2.18621 22.3574 2.17248C22.8182 2.19145 23.3252 2.15896 23.7258 2.43417C24.0065 2.83761 23.974 3.35009 23.993 3.8135C23.9794 8.54576 23.9849 13.2754 23.9877 18.0048C23.9742 18.7982 24.0612 19.6077 23.895 20.3901C23.786 20.9571 23.1045 20.9706 22.6492 20.9898C19.8251 20.9979 16.9984 20.9844 14.1715 20.9898V23.4432H12.4785C8.32418 22.688 4.16154 21.9904 0.00195312 21.2624V2.1836C4.1864 1.45566 8.37063 0.738842 12.5522 0Z"
                fill="#207245"
            />
            <path
                d="M14.1714 2.99854H23.167V20.172H14.1714V18.5365H16.3521V16.6283H14.1714V15.5379H16.3521V13.6298H14.1714V12.5394H16.3521V10.6312H14.1714V9.54082H16.3521V7.63266H14.1714V6.54227H16.3521V4.63411H14.1714V2.99854Z"
                fill="white"
            />
            <path
                d="M17.4424 4.63428H21.2587V6.54244H17.4424V4.63428Z"
                fill="#207245"
            />
            <path
                d="M8.07596 7.18832C8.69202 7.1447 9.31093 7.10654 9.92961 7.07666C9.20289 8.56911 8.46964 10.0584 7.72987 11.5444C8.47962 13.0709 9.24528 14.5866 9.99765 16.1131C9.34142 16.0754 8.68543 16.0336 8.02973 15.9877C7.56631 14.8509 7.00193 13.7524 6.66937 12.5639C6.29886 13.6706 5.7698 14.7146 5.34477 15.7997C4.74768 15.7914 4.15059 15.767 3.55371 15.7424C4.25417 14.3713 4.93021 12.9891 5.65248 11.6262C5.03925 10.2222 4.36583 8.84569 3.73341 7.45001C4.33312 7.41446 4.93283 7.37913 5.53254 7.34642C5.93859 8.41238 6.38281 9.46438 6.71843 10.5576C7.07804 9.39917 7.61516 8.30879 8.07596 7.18832Z"
                fill="white"
            />
            <path
                d="M17.4424 7.63281H21.2587V9.54098H17.4424V7.63281ZM17.4424 10.6314H21.2587V12.5395H17.4424V10.6314ZM17.4424 13.6299H21.2587V15.5381H17.4424V13.6299ZM17.4424 16.6285H21.2587V18.5366H17.4424V16.6285Z"
                fill="#207245"
            />
        </g>
        <defs>
            <clipPath id="clip0_2_502">
                <rect width="24" height="23.4432" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
