type Question = {
  id: number;
  que: string;
  ans: string[];
};

export const Questions: Question[] = [
  {
    id: 1,
    que: "Как пользоваться сервисом?",
    ans: [
      "Нужно ответить на вопросы - ввести название товара, его категорию и добавить имующуюся информацию о товаре. Нейросеть создаст описание товара с адаптацией под Озон и Вб. Как правило, описания Мефодия нуждаются в дополнительной редактуре.",
    ],
  },
  {
    id: 2,
    que: "Чем отличается Мефодий от аналогичных сервисов?",
    ans: [
      "У нас три основных преимущества:",
      "1) нейросеть специально настроена на создание описаний для маркетплейсов с адаптацией под Озон и Вайлдберис.",
      "2) массовая генерация - самый удобный способ для создания описаний для множества товаров одновременно",
      "3) у наших клиентов есть возможность заказать описания под ключ. В этом случае наш копирайтер доработает описания Мефодия и уберет возможные недочеты.",
      "4) Возможность создания Rich-контента.",
    ],
  },
  {
    id: 3,
    que: "Что такое массовая генерация",
    ans: [
      "Массовая генерация - самый удобный способ для создания описаний для множества товаров одновременно. В этом случае нужно заполнить шаблон - эксель-таблицу - и нейросеть заполнит таблицу готовыми описаниями под все товары. Этот способ не только очень быстрый, но и хорошо структурирует работу с описаниями товаров.",
    ],
  },
  {
    id: 4,
    que: "Можно ли пользоваться сервисом бесплатно?",
    ans: [
      "У Мефодия есть бесплатная версия, в рамках которой вы можете создавать по 3 описания в сутки и 2 массовых описания.",
    ],
  },
  {
    id: 5,
    que: "Где скачать результат массовой генерации?",
    ans: [
      "Все результаты массовой генерации появляются в истории описаний. Как правило, массовая генерация занимает 10-15 минут.",
    ],
  },
  {
    id: 6,
    que: "Для каких пользователей подходит использование Мефодия?",
    ans: [
      "Мефодий лучше всего подойдет для средних и крупных селлеров, которые добавляют в ассортимент большое количество новинок и для которых хорошо структурированный процесс работы с описаниями товаров является необходимостью. Тарифы Стандарт и Бизнес созданы специально для таких комппаний, а более легкие тарифы подойдут, если у вас небольшое количество новинок, либо вы хотите проверить как Мефодий работает.",
    ],
  },
];
