export const Buy = () => {
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.5611 18.0001L20.5286 17.0288C20.6081 16.9476 20.6681 16.8494 20.7041 16.7415C20.7401 16.6337 20.7511 16.5191 20.7363 16.4064C20.7215 16.2937 20.6812 16.1859 20.6186 16.091C20.556 15.9961 20.4726 15.9167 20.3748 15.8588L12.8748 11.3588C12.7328 11.2821 12.5698 11.2533 12.41 11.2767C12.2503 11.3 12.1024 11.3743 11.9882 11.4885C11.8741 11.6026 11.7998 11.7506 11.7764 11.9103C11.7531 12.07 11.7819 12.233 11.8586 12.3751L16.3586 19.8751C16.4174 19.9724 16.4977 20.055 16.5932 20.1167C16.6887 20.1784 16.7969 20.2176 16.9098 20.2313C16.9389 20.2412 16.9692 20.2475 16.9998 20.2501C17.1984 20.2492 17.3886 20.1697 17.5286 20.0288L18.4998 19.0613L20.9711 21.5288C21.1146 21.6517 21.2991 21.7159 21.4879 21.7086C21.6766 21.7013 21.8557 21.6231 21.9893 21.4895C22.1228 21.3559 22.2011 21.1769 22.2084 20.9881C22.2157 20.7994 22.1514 20.6148 22.0286 20.4713L19.5611 18.0001ZM17.1498 18.2888L14.6861 14.1863L18.7886 16.6501L17.1498 18.2888Z"
                fill="black"
            />
            <path
                d="M12.5002 2.25C11.3567 2.25099 10.2604 2.70566 9.45186 3.5142C8.64333 4.32273 8.18866 5.41906 8.18766 6.5625V6.75H6.68766C6.14508 6.75124 5.62122 6.94851 5.21258 7.30546C4.80395 7.66241 4.53806 8.15501 4.46391 8.6925L3.00891 19.1925C2.96516 19.5096 2.98956 19.8323 3.08049 20.1392C3.17141 20.4461 3.32676 20.7301 3.53618 20.9721C3.74559 21.2142 4.00423 21.4088 4.29485 21.5429C4.58546 21.6771 4.90135 21.7477 5.22141 21.75H16.4714L15.5714 20.25H5.22141C5.11379 20.251 5.00722 20.2288 4.90893 20.1849C4.81065 20.1411 4.72295 20.0766 4.6518 19.9958C4.58065 19.9151 4.52772 19.82 4.49659 19.7169C4.46547 19.6139 4.45688 19.5054 4.47141 19.3988L5.92641 8.89875C5.95105 8.71786 6.04082 8.55217 6.17889 8.43273C6.31697 8.3133 6.49386 8.24833 6.67641 8.25L18.3614 8.2275C18.544 8.22583 18.7209 8.2908 18.8589 8.41023C18.997 8.52967 19.0868 8.69536 19.1114 8.87625L19.8614 14.5575L21.5077 15.5438L20.5927 8.67C20.5201 8.1305 20.2544 7.63561 19.8448 7.27705C19.4352 6.9185 18.9095 6.72058 18.3652 6.72H16.8127V6.5625C16.8117 5.41906 16.357 4.32273 15.5485 3.5142C14.7399 2.70566 13.6436 2.25099 12.5002 2.25ZM15.3127 6.7275H9.68766V6.5625C9.68766 5.81658 9.98398 5.10121 10.5114 4.57376C11.0389 4.04632 11.7542 3.75 12.5002 3.75C13.2461 3.75 13.9615 4.04632 14.4889 4.57376C15.0163 5.10121 15.3127 5.81658 15.3127 6.5625V6.7275Z"
                fill="black"
            />
        </svg>
    );
};
